import React, { useEffect, useState } from 'react';
import { CgCalculator } from "react-icons/cg";
import { FaStar } from "react-icons/fa";
import { FaHandshakeAngle, FaHouseLaptop, FaPeopleGroup } from 'react-icons/fa6';
import { GiClockwork, GiProgression } from 'react-icons/gi';
import { LiaSwatchbookSolid } from "react-icons/lia";
import { Link } from 'react-router-dom';
import BgImage from "../../assets/images/businessStratup.png";
import headerImg from "../../assets/images/headerImg.png";
import RegulatoryComplience from '../../assets/images/Regulatory_Complience.png';
import Faq from '../../components/Faq';
import HomeCard from '../../components/HomeCard';
import LazyImg from '../../components/LazyImg';
import Meta from '../../components/Meta';
import RotatingCircle from '../../components/RotatingCircle/RotatingCircle';
import Testimonial from '../../components/Testimonial';
import Timeline from '../../components/Timeline';
import VATCalculator from '../../components/VATCalculator';


// import CountUp from 'react-countup';

export default function HomePage() {
    const [entity, setEntity] = useState('');
    const [state, setState] = useState('');
    const [responsiveClass, setResponsiveClass] = useState(false);
    const handleEntityChange = (event) => {
        setEntity(event.target.value);
    };
    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const faqItems = [
        {

            title: 'How to incorporate/register a company in Bangladesh ?',
            content: <strong>Yes, Foreign investors can incorporate a fully owned company, subsidiary or have a jointly owned company in Bangladesh.</strong>
        },
        {
            title: 'Foreign Company Registration in Bangladesh ?',
            content: <strong>To set up a Bangladesh company, your first step would be to obtain a name clearance for the proposed company name. You will have to visit <a href="www.roc.gov.bd">www.roc.gov.bd</a></strong>
        },
        {
            title: 'Trademark Registration Process in Bangladesh ?',
            content: <p>Trademark Registration Process. <strong>Step 1: Searching for Trademark Registration . Step2: Power of Power of Attorney Step 3: Filling an Application for Trademark Registration, Next Up to Final Registration.</strong></p>
        }
    ];
    const bgStyle = {
        backgroundImage: `url(${BgImage})`,
        width: 'auto',
        // backgroundSize: 'cover',
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "282px"
    };
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setResponsiveClass(true);
            } else {
                setResponsiveClass(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <main style={{ maxWidth: "100%" }}>
            <Meta title={"JK Associates | Setup Foreign Company Registration | Taxation"}
                description="JK Associates is top-ranked Corporate Consultancy and Law firm in Bangladesh with the international presence provides specialized services in Trademark, Corporate Affairs, Foreign Company Registration and FDI Investment, Taxation, Business Registration."
                image={RegulatoryComplience}
                keywords="Setup Foreign Company, Foreign Company Registration, Company Registration, Tax Lawyer, VAT Consultant, Trademark Registration, copyright Registration"
            />
            {/* header start  */}
            <section className='heroSection'>
                <div className='r-container'>
                    <div style={{ justifyContent: "space-between" }} className='row row-cols-lg-2 row-cols-1 align-items-center'>
                        <div className='d-flex flex-column gap-4 pe-lg-5 m-margin-top-1 z-0'>
                            <span style={{ color: "#4caf50" }} class="font-2 m-text-center m-text-sm">Welcome to JK Associates Group</span>
                            <Link className='m-d-none' target='_blank' to="https://www.google.com/maps/place/JK+Associates+%7C+Income+Tax+%7C+VAT+%7C+Company+Registration+%7C+Copyright+%7C+Trademark/@23.7927683,90.374504,17z/data=!4m8!3m7!1s0x3755b8bf5300eaad:0xe50b10a249f6394e!8m2!3d23.7927683!4d90.374504!9m1!1b1!16s%2Fg%2F11c54h2yxw?entry=ttu">
                                <span className='home-top bg-secondary opacity-75 p-2'>
                                    Excellent 5 out of 5&nbsp;
                                    <FaStar style={{ color: "yellow", marginBottom: "5px" }} /> Google
                                </span>
                            </Link>

                            <h1 class="font-3 fw-bold m-text-xl m-text-center" style={{ marginBottom: "0" }}>Make Your Business With Us</h1>
                            <p className="animated slideInDown" style={{ textAlign: 'justify' }}>From anywhere in the world, our complete platform simplifies the Bangladesh company registration, allowing you to establish your business​. JK Associates" company formation for non-Bangladesh residents. Easily start a Bangladesh business, online.</p>
                            <div className="d-flex align-items-center justify-content-center dropdown-11">
                                <label htmlFor="formFile" className="form-label me-5 mb-0">Entity</label>
                                <select className="form-select mb-0" aria-label="Default select example" value={entity} onChange={handleEntityChange}>
                                    <option value="">Select Entity</option>
                                    <option value="Limited / Ltd.">Limited / Ltd.</option>
                                    <option value="One Person Company (OPC)">One Person Company (OPC)</option>
                                    <option value="Society Registration">Society Registration</option>
                                    <option value="Partnership Firm Registration">Partnership Firm Registration</option>
                                </select>
                            </div>
                            <div className="mb-3 d-flex align-items-center justify-content-center dropdown-11">
                                <label htmlFor="formFile" className="form-label me-5 mb-0">Division</label>
                                <select className="form-select mb-0" aria-label="Default select example" value={state} onChange={handleStateChange}>
                                    <option value="">Select Division</option>
                                    <option value="Dhaka">Dhaka</option>
                                    <option value="Chittagong">Chittagong</option>
                                    <option value="Khulna">Khulna</option>
                                    <option value="Rajshahi">Rajshahi</option>
                                </select>
                            </div>
                            {(entity && state) ? (
                                <Link class="" to='/get-started-page' className="m-text-center">
                                    <button className='form-submit-button'>Get Started</button>
                                </Link>
                            ) : ""}
                        </div>
                        {/* header right side img */}
                        <RotatingCircle />
                        {/* <div className='col col-lg-5 ps-0 mt-5' style={{ position: "relative" }}>
                            <div className='mb-5 pb-5 px-5 m-d-none'>
                                <LazyImg img={{src:headerImg,alt:"Bussiness Setup Bangladesh"}} className="heroImg"/>
                            </div>

                        </div> */}

                    </div>
                </div>

            </section>
            {/* header end  */}

            {/* about us start */}
            <section className='heroSection margin-top-xl m-margin-top-1'>
                <div className='r-container'>
                    <div style={{ justifyContent: "space-between", }} className='row row-cols-lg-2 row-cols-1 align-items-center'>
                        <div className='col col-lg-6 ps-0 d-none d-sm-block'>
                            <div className='mb-5 pb-5 px-5'>

                                <LazyImg img={{ src: headerImg, alt: "Bussiness Setup Bangladesh" }} className="heroImg" />
                            </div>

                        </div>
                        <div className='col col-lg-6 ps-0 mt-5'>
                            <div style={{ paddingBottom: "60px", borderBottom: "1px solid #484848" }} className='d-flex flex-column gap-4 pe-lg-5'>
                                <span style={{
                                    color: "#4caf50", fontSize: "18px"
                                }} class="font-2 m-text-center">About Us</span>
                                <h1 style={{ marginBottom: 0 }} class="font-3 fw-bold m-text-lg m-text-center">Your Trusted Advisors in Business Success.</h1>
                                <p className="" style={{ textAlign: 'justify' }}><b>"JK Associates"</b>is top-ranked Corporate Consultancy and Law
                                    firm in Bangladesh with the international presence provides specialized services in Intellectual
                                    Property Rights, Corporate Affairs, Foreign Trade and Investment, Taxation, and Litigation.</p>
                                <div className='m-margin-auto'>

                                    <Link to="/about-us"><button className='form-submit-button '>More about JK Associates</button></Link>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <FaHandshakeAngle className='text-xxl m-text-xxl' style={{ color: '#4caf50', textAlign: "center", display: "block", margin: "auto", border: "1px solid", padding: "5px", borderRadius: "50%" }} />
                                    <div>
                                        <h2 className='mt-3 m-text-lg text-xl' style={{ color: '#4caf50', textAlign: "center" }}>550+</h2>
                                    </div>
                                    <div>
                                        <h2 style={{ color: '#4caf50', fontSize: "1rem", textAlign: "center" }} className='one-rem-text'>
                                            Global Clients
                                        </h2>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <FaPeopleGroup className='text-xxl m-text-xxl' style={{ color: '#4caf50', textAlign: "center", display: "block", margin: "auto", border: "1px solid", padding: "5px", borderRadius: "50%" }} />
                                    <div>
                                        <h2 className='mt-3 m-text-lg text-xl' style={{ color: '#4caf50', textAlign: "center" }}>10+</h2>
                                    </div>
                                    <div>
                                        <h2 style={{ color: '#4caf50', fontSize: "1rem", textAlign: "center" }}>
                                            Specialists
                                        </h2>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <GiProgression className='text-xxl m-text-xxl' style={{ color: '#4caf50', textAlign: "center", display: "block", margin: "auto", border: "1px solid", padding: "5px", borderRadius: "50%" }} />
                                    <div>
                                        <h2 className='mt-3 m-text-lg text-xl' style={{ color: '#4caf50', textAlign: "center" }}>{Number(new Date().getFullYear()) - 2017}+</h2>
                                    </div>
                                    <div>
                                        <h2 style={{ color: '#4caf50', fontSize: "1rem", textAlign: "center" }}>
                                            Year of experience
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            {/* about us end */}

            <section>
                <h1 style={{ textAlign: "center", marginTop: "40px" }}>The One-Stop Solution For Entrepreneurs</h1>
                <div className="resource-container">
                    <div className="resource-card-container">
                        <div className="resource-card">
                            <GiClockwork style={{ fontSize: "110px", color: "#4caf50", margin: "20px 0" }} />

                            <h3 >On-the-ground experts</h3>
                            <p>
                                Unlike competitors, we have a fully-fledged tax team based in
                                Bangladesh. Our experts are directly involved in a situation or are at
                                the place of your interest. Get answers through the app’s live chat, via
                                email, or phone for easy access to advice and in-person consultation.
                            </p>
                        </div>
                        <div className="resource-card">
                            <FaHouseLaptop style={{ fontSize: "110px", color: "#4caf50", margin: "20px 0" }} />

                            <h3 >Get service remotely</h3>
                            <p>
                                Start a company and open an Aspire business account from anywhere in the world.We assist you online and help you with local compliance.
                            </p>
                        </div>
                        <div className="resource-card">
                            <CgCalculator style={{ fontSize: "110px", color: "#4caf50", margin: "20px 0" }} />

                            <h3 >Tax right from day one</h3>
                            <p>
                                We give you guidance on the best business structure, set up your accounting, and inform you on tax reliefs available to your business so that you're set up to pay tax efficiently from the get-go.
                            </p>
                        </div>
                        <div className="resource-card">
                            <LiaSwatchbookSolid style={{ fontSize: "110px", color: "#4caf50", margin: "20px 0" }} />

                            <h3 >Long-term Compliance support</h3>
                            <p>
                                Regulatory compliance support helps organizations maintain integrity, protect their reputation, and avoid penalties. It includes ongoing attention to regulatory changes, employee training, policy updates, and risk management, beyond initial setup and annual audits.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <div className="container-xxl">
                <div>
                    <VATCalculator />
                </div>
            </div>
            <div className='container-xxl text-center'>
                <h1>Where Global Entrepreneurs Thrive</h1>
                <p className="fs-5 text-center">We make launching your Bangladesh business easy, fast, and effective</p>
                <Link to={'/business-setup-package'} className='btn btn-primary fs-3' style={{ width: "215px" }}>Get Started</Link>
            </div>
            <div className="container-xxl">
                <HomeCard />
            </div>
            {
                responsiveClass ? <div className='m-d-block  container-xxl text-center'>
                    <img src={BgImage} style={{ display: "block", margin: "auto" }} alt="" />
                    <h1>How to open Company in Bangladesh</h1>
                </div>
                    :
                    <div style={{ marginTop: "60px", ...bgStyle }} className='container-xxl text-center'>
                        <h1>How to open Company in Bangladesh</h1>
                    </div>
            }

            <div className="container-xxl">
                <Timeline />
            </div>
            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Frequently Asked Questions</h2>
                <Faq items={faqItems} />
            </div>

            <div className="r-container py-5 my-5">
                <Link style={{ display: "block", textAlign: "center", marginBottom: "20px" }} target='_blank' to="https://www.google.com/maps/place/JK+Associates+%7C+Income+Tax+%7C+VAT+%7C+Company+Registration+%7C+Copyright+%7C+Trademark/@23.7927683,90.374504,17z/data=!4m8!3m7!1s0x3755b8bf5300eaad:0xe50b10a249f6394e!8m2!3d23.7927683!4d90.374504!9m1!1b1!16s%2Fg%2F11c54h2yxw?entry=ttu">
                    <span className='home-top bg-secondary opacity-75 p-2'>
                        Excellent 5 out of 5&nbsp;
                        <FaStar style={{ color: "yellow", marginBottom: "5px" }} /> Google
                    </span>
                </Link>
                <h1 className='text-center mb-4'>Customer Experiences With Us</h1>
                <Testimonial isResponsive={responsiveClass} />
            </div>

        </main>
    )
}
