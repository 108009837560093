import React, { useEffect, useState } from "react";
import { FaHandshakeAngle, FaPeopleGroup, FaStar } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import { Link } from "react-router-dom";
import JKLOGO from "../assets/images/JK Associates Log.png";
import aboutImg from "../assets/images/about.jpg";
import headerImg from "../assets/images/headerImg.png";
import LazyImg from "../components/LazyImg";
import Meta from "../components/Meta";
import Testimonial from "../components/Testimonial";

export default function About() {
    const [responsiveClass, setResponsiveClass] = useState(false);
        useEffect(() => {
            const handleResize = () => {
                if (window.innerWidth < 768) {
                    setResponsiveClass(true);
                } else {
                    setResponsiveClass(false);
                }
            };
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
  return (
    <>
      <Meta
        title="About with JK Associates"
        description="Bangladesh government allowed 100% equity ownership foreign investor for business setup in bangladesh. Also, offered tax benefit and land, and policy and regulatory support. 
        A foreign equity company can buy land and properties in bangladesh with registered entity. Foreign investors can incorporate a fully owned company, subsidiary or have a jointly owned company in Bangladesh. 
        A foreign investor is required to be registered with BIDA. It is essential to obtain any licenses 
        and permits such as Import Registration Certificate, Export"
        // image={openForeignCompany_OG_img}
        keywords="Foreign Company Registration, Setup Foreign Company Bangladesh, Company registration in Bangladesh, Foreign Company Formation in Bangladesh, Foreign company in Bangladesh, How to register a foreign company in Bangladesh, Company formation in Bangladesh, online company registration in bangladesh"
      />

      <div
        style={{ maxWidth: "1200px", margin: "auto", padding: "40px 20px" }}
        className="container-fluid hero-header bg-light"
      >
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            {/* Image Column */}
            <div className="col-lg-6 order-1 order-lg-2 animated fadeIn">
              <img
                className="img-fluid animated pulse infinite"
                style={{
                  animationDuration: "3s",
                  display: "block",
                  margin: "auto",
                  width: "250px",
                }}
                src={JKLOGO}
                alt="Foreign Company Registration in Bangladesh"
              />
            </div>
            {/* Text Column */}
            <div className="col-lg-6 order-2 order-lg-1">
              <h2
                style={{ fontSize: "2.5rem" }}
                className="display-4 mb-3 animated slideInDown text-center text-lg-start"
              >
                About JK ASSOCIATES
              </h2>
              <p className="" style={{ textAlign: "justify" }}>
                <b>"JK Associates"</b> is a top-ranked Corporate Consultancy and
                Law firm in Bangladesh with an international presence that
                provides specialized services in Intellectual Property Rights,
                Corporate Affairs, Foreign Trade and Investment, Taxation, and
                Litigation.
              </p>
            </div>
          </div>
        </div>
        <section className="heroSection margin-top-xl m-margin-top-1">
          <div className="r-container">
            <div
              style={{ justifyContent: "space-between" }}
              className="row row-cols-lg-2 row-cols-1 align-items-center"
            >
              <div className="col col-lg-6 ps-0 d-none d-sm-block">
                <div className="mb-5 pb-5 px-5">
                  <LazyImg
                    img={{ src: headerImg, alt: "Bussiness Setup Bangladesh" }}
                    className="heroImg"
                  />
                </div>
              </div>
              <div className="col col-lg-6 ps-0 ">
                <div
                  style={{
                    paddingBottom: "60px",
                    borderBottom: "1px solid #484848",
                  }}
                  className="d-flex flex-column gap-4 pe-lg-5"
                >
                  <p>
                    The firm has a constellation of experienced and proficient
                    lawyers in the relevant fields of Intellectual Property i.e.
                    Trademarks, Patents, Copyright, Industrial Designs, Brand
                    Protection, and Brand enforcement. The proactive team
                    members are also experienced in Company Startup planning,
                    Company Registration, Export-Import License, Foreign Company
                    Formation, Foreign Direct Investment (FDI), BIDA Permission,
                    Work Permit VISA, Foreign Delegates, Security Clearance.
                    Business- related licenses registration, Income Tax, VAT
                    Litigation and, Company Law.
                  </p>
                  <p>
                    With the latest Information Technology tools, JK Associates
                    provides high quality, low cost, and speedy Legal Services.
                    The firm is proud to provide services to many local and
                    world-renowned companies.
                  </p>
                  <p>
                    Our sincere thanks for your valued business and profession.
                    We appreciate having you as a part of us and look forward to
                    serving you.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    flexWrap: "wrap", // Allows wrapping on small screens
                  }}
                >
                  <div className="col-4">
                    <FaHandshakeAngle
                      className="text-xxl m-text-xxl"
                      style={{
                        color: "#4caf50",
                        textAlign: "center",
                        display: "block",
                        margin: "auto",
                        border: "1px solid",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <h2
                        className="mt-3 m-text-lg text-xl"
                        style={{ color: "#4caf50", textAlign: "center" }}
                      >
                        550+
                      </h2>
                    </div>
                    <div>
                      <h2
                        style={{
                          color: "#4caf50",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                        className="one-rem-text"
                      >
                        Global Clients
                      </h2>
                    </div>
                  </div>
                  <div className="col-4">
                    <FaPeopleGroup
                      className="text-xxl m-text-xxl"
                      style={{
                        color: "#4caf50",
                        textAlign: "center",
                        display: "block",
                        margin: "auto",
                        border: "1px solid",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <h2
                        className="mt-3 m-text-lg text-xl"
                        style={{ color: "#4caf50", textAlign: "center" }}
                      >
                        10+
                      </h2>
                    </div>
                    <div>
                      <h2
                        style={{
                          color: "#4caf50",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Specialists
                      </h2>
                    </div>
                  </div>
                  <div className="col-4">
                    <GiProgression
                      className="text-xxl m-text-xxl"
                      style={{
                        color: "#4caf50",
                        textAlign: "center",
                        display: "block",
                        margin: "auto",
                        border: "1px solid",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <h2
                        className="mt-3 m-text-lg text-xl"
                        style={{ color: "#4caf50", textAlign: "center" }}
                      >
                        {Number(new Date().getFullYear()) - 2017}+
                      </h2>
                    </div>
                    <div>
                      <h2
                        style={{
                          color: "#4caf50",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Year of experience
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="resource-container mt-5">
          <h2
            style={{
              fontSize: "2.5rem",
              width: "70%",
              margin: "auto",
              fontWeight: "bold",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            Dedicated to Supporting Your Growth and Success
          </h2>
          <div className="resource-card-container mt-5">
            <div className="resource-card">
              <svg
                viewBox="0 -94 1024 1213"
                preserveAspectRatio="xMinYMin meet"
                fill="#4caf50"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="200px"
                height="200px"
              >
                <path d="M613.243 528.737c-64.092 0-116.234-52.142-116.234-116.234s52.142-116.234 116.234-116.234 116.234 52.142 116.234 116.234-52.142 116.234-116.234 116.234zM613.243 325.898c-47.754 0-86.605 38.851-86.605 86.605s38.851 86.605 86.605 86.605 86.61-38.851 86.61-86.605-38.851-86.605-86.61-86.605z"></path>
                <path d="M787.236 728.893l-31.688 33.004v-103.48c0-38.513-22.313-72.817-56.402-88.095-23.747 21.484-53.868 33.183-85.903 33.183s-62.167-11.704-85.914-33.183c-34.084 15.278-56.392 49.582-56.392 88.095v105.733l-31.688-33.004v-72.73c0-55.66 34.913-104.74 86.871-122.127l9.477-3.174 6.902 7.322c18.719 19.866 43.843 30.807 70.743 30.807s52.019-10.941 70.738-30.807l6.902-7.322 9.477 3.174c51.958 17.388 86.871 66.468 86.871 122.127v70.477z"></path>
                <path d="M704.681 1019.535l-96.077-96.343-96.077 96.343-505.467-506.88 505.472-506.88 96.077 96.343 96.072-96.343 505.472 506.88-505.472 506.88zM641.039 890.665l63.642 63.816 440.596-441.825-440.596-441.825-63.642 63.816 376.965 378.010-376.965 378.010zM71.931 512.655l440.602 441.825 63.642-63.816-376.965-378.010 376.96-378.004-63.642-63.816-440.596 441.82zM264.074 512.655l344.53 345.482 344.525-345.482-344.525-345.482-344.53 345.482z"></path>
              </svg>

              <h2
                style={{
                  width: "70%",
                  margin: "auto",
                  fontWeight: "bold",
                  marginBottom: "40px",
                  textAlign: "center",
                }}
                className="fs-3 fs-md-2 fs-lg-1"
              >
                Dedicated to Supporting Your Growth and Success
              </h2>
              <p>
                Every business has unique needs, requiring a distinct approach
                to achieve success. As your trusted consultancy partner, we
                provide personalized support to help you navigate challenges,
                achieve compliance, and bring your vision to life. Our focus is
                on understanding your goals and delivering tailored solutions to
                meet them effectively.
              </p>
            </div>
            <div className="resource-card">
              <svg
                viewBox="0 -94 1024 1213"
                preserveAspectRatio="xMinYMin meet"
                fill="#4caf50"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="200px"
                height="200px"
              >
                <path d="M646.6 510.024l-135.562 135.572-135.557-135.572 135.557-135.562 135.562 135.562zM511.037 582.958l72.934-72.934-72.934-72.929-72.929 72.929 72.929 72.934z"></path>
                <path d="M324.198 827.438l186.829 186.834 186.839-186.834-35.215-35.21-151.619 151.624-151.619-151.629-35.215 35.215z"></path>
                <path d="M6.789 510.029l186.834 186.834 35.215-35.21-151.624-151.619 151.624-151.619-35.22-35.21-186.829 186.824z"></path>
                <path d="M793.231 358.405l151.624 151.624-151.624 151.624 35.21 35.21 186.834-186.839-186.834-186.829-35.21 35.21z"></path>
                <path d="M511.037 5.786l-186.834 186.834 35.21 35.215 151.629-151.624 151.613 151.624 35.21-35.215-186.829-186.834z"></path>
                <path d="M486.139 176.123h49.792v168.11h-49.792v-168.11z"></path>
                <path d="M177.137 485.13h168.11v49.792h-168.11v-49.792z"></path>
                <path d="M676.828 485.13h168.11v49.792h-168.11v-49.792z"></path>
                <path d="M486.139 675.825h49.797v168.11h-49.797v-168.11z"></path>
              </svg>

              <h3
                style={{
                  marginTop: "45px",
                  marginBottom: "25px",
                  textAlign: "center",
                  fontSize: "22px",
                }}
              >
                Moving With Change
              </h3>
              <p>
                The business landscape is constantly evolving, bringing new
                opportunities and challenges. At JK Associates, we stay ahead of
                change, helping you adapt seamlessly to emerging trends and
                legal requirements. With our proactive guidance, your business
                can thrive in a rapidly transforming environment.
              </p>
            </div>
            <div className="resource-card">
              <svg
                viewBox="0 -94 1024 1213"
                preserveAspectRatio="xMinYMin meet"
                fill="#4caf50"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="230px"
                height="160px"
                style={{ marginTop: "15px", marginBottom: "30px" }}
              >
                <path d="M597.115 510.403l422.011-421.161 422.006 421.161-422.006 421.161-422.011-421.161zM1019.126 5.919l-505.508 504.484 505.508 504.484 505.503-504.484-505.503-504.484z"></path>
                <path d="M513.971 5.919l-505.513 504.484 505.513 504.484 41.748-41.667-463.764-462.817 463.764-462.817-41.748-41.667z"></path>
                <path d="M1271.7 5.919l-41.743 41.667 463.754 462.817-463.754 462.817 41.743 41.667 505.508-504.484-505.508-504.484z"></path>
                <path d="M344.535 510.403l422.011-421.161 422.011 421.161-422.011 421.161-422.011-421.161zM766.546 5.919l-505.508 504.484 505.508 504.484 505.508-504.484-505.508-504.484z"></path>
                <path d="M795.602 510.397l97.234 97.034 97.229-97.034-97.229-97.029-97.234 97.029zM892.836 690.76l-180.726-180.362 180.726-180.352 180.721 180.352-180.721 180.362z"></path>
              </svg>

              <h3
                style={{
                  marginTop: "45px",
                  marginBottom: "25px",
                  textAlign: "center",
                  fontSize: "22px",
                }}
              >
                Reimagine Higher Ed
              </h3>
              <p>
                Achieving sustainable growth requires expert guidance and a
                strategic approach. We partner with you to streamline processes,
                resolve complex legal matters, and build a solid foundation for
                success. Together, we ensure your business reaches new heights
                while maintaining compliance and professionalism.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section
        style={{
          background: "linear-gradient(90deg, #4caf50,rgb(56, 172, 60))",
          padding: "100px 20px",
          position: "relative",
          marginBottom: "100px", // Allow space for the overlap
        }}
      >
        {/* Header Section */}
        <div
          className="text-center text-white"
          style={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <p
            className="mb-0 fw-semibold"
            style={{
              fontSize: "20px",
              lineHeight: "1.5",
            }}
          >
            From 2017, JK Associates is a highly regarded law and consultancy
            firm with over 550 clients and outstanding reviews, known for
            delivering exceptional legal services in Bangladesh and beyond.
          </p>
        </div>

        {/* Mission and Vision Section */}
        <div
          className="container"
          style={{
            position: "relative",
            marginTop: "50px", // Prevent overlap on mobile
          }}
        >
          <div className="row justify-content-center">
            {/* Mission Card */}
            <div className="col-12 col-md-5 mb-4 mb-md-0">
              <div
                className="p-4 rounded shadow-sm text-center"
                style={{
                  backgroundColor: "#fff9f0",
                  borderRadius: "12px",
                }}
              >
                <h3
                  className="fw-bold"
                  style={{
                    color: "#4caf50",
                    fontSize: "1.5rem",
                  }}
                >
                  Mission
                </h3>
                <p className="text-muted mt-3">
                  Advance the common good through quality assurance of higher
                  education as the leader in equitable, transformative, and
                  trusted accreditation in the service of students and member
                  institutions.
                </p>
              </div>
            </div>

            {/* Vision Card */}
            <div className="col-12 col-md-5">
              <div
                className="p-4 rounded shadow-sm text-center"
                style={{
                  backgroundColor: "#fff9f0",
                  borderRadius: "12px",
                }}
              >
                <h3
                  className="fw-bold"
                  style={{
                    color: "#4caf50",
                    fontSize: "1.5rem",
                  }}
                >
                  Vision
                </h3>
                <p className="text-muted mt-3">
                  HLC will be the champion of quality higher education by
                  working proactively in support of students, institutions, and
                  their communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="resource-container mt-5">
          <h2
            style={{
              fontSize: "2.5rem",
              width: "70%",
              margin: "auto",
              fontWeight: "bold",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            Our Service
          </h2>
          <div className="container mt-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-3">
  {/* Business Startup */}
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  <div className="col">
    <div
      className="resource-card p-3 d-flex flex-column justify-content-between"
      style={{ minHeight: "350px" }}
    >
      <h3
        style={{
          marginTop: "45px",
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        Business Startup
      </h3>
      <ul style={{ margin: "auto", textAlign: "left" }}>
        <li>Company Formation</li>
        <li>Society Registration</li>
        <li>Partnership Registration</li>
        <li>Import License (IRC)</li>
        <li>Export License (ERC)</li>
        <li>BSTI Permission</li>
        <li>Trade License</li>
        <li>Fire License</li>
        <li>Customs Bond License</li>
        <li>Factory License (DIFE)</li>
        <li>Environment License</li>
      </ul>
    </div>
  </div>
  {/* Repeat for other sections */}
</div>

          </div>
        </div>
      </section>
      {/* <section style={{ padding: "50px 40px" }}>
        <div className="container">
       
          <h2
            style={{
              color: "#4caf50",
              fontSize: "2.5rem",
              fontWeight: "bold",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            Leadership
          </h2>

          <div className="row">
            {
              teamMembers.map((item,index)=>(
                <div className="col-md-4 d-flex flex-column align-items-center text-center mb-4">
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "12px",
                    overflow: "hidden",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={item.img}
                    alt="President and Executive Leadership Team"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <small style={{color:"#4e0279",fontWeight:"600"}}>{item.name}</small>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#4e0279",
                    margin:0
                  }}
                >
                  {item.title}
                </p>
              </div>
              ))
            }
           
          </div>
        </div>
      </section> */}
      <section  style={{ maxWidth: "1200px", margin: "auto", padding: "40px 20px" }}
        className="container-fluid hero-header bg-light">
        <div className="container py-5 d-flex justify-content-center align-items-center">
          <div className="row g-5 align-items-center">
            {/* Image Column */}
            <div className="col-lg-6 order-1 order-lg-2 text-center">
              <img
                className="img-fluid mb-4 mb-lg-0"
                src={aboutImg}
                alt="Foreign Company Registration in Bangladesh"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            {/* Text Column */}
            <div className="col-lg-6 order-2 order-lg-1">
              <h3 style={{ color: "#4caf50" }}>
                Relationship Within the Triad
              </h3>
              <p>
                As an accreditor, HLC is one member of the program integrity
                Triad, which consists of three areas of oversight for higher
                education in the United States. The Federal government and the
                state in which the institution is located are the other two
                areas. The components of the Triad operate independently to
                focus on issues within their specific scope.
              </p>
              <p>
                In order to assure academic quality as part of the Triad,
                accreditors have requirements for institutions. To be a member,
                colleges and universities provide evidence that they meet these
                requirements in alignment with the institution’s mission. The
                college or university’s mission is central to accreditation and
                assurance of academic quality.
              </p>
              <p>
                Should any of the requirements overlap within the Triad, we work
                with the other Triad members to identify them and limit the
                burden on the institution where possible.
              </p>
              <p>
                HLC’s requirements reflect the values of academic quality at HLC
                as an organization as well as at its member institutions. These
                values include academic freedom in teaching and learning as well
                as diversity, equity, inclusion, and accessibility.
              </p>
            </div>
          </div>
        </div>
          <div className="r-container py-5 my-5">
                        <Link style={{ display: "block", textAlign: "center", marginBottom: "20px" }} target='_blank' to="https://www.google.com/maps/place/JK+Associates+%7C+Income+Tax+%7C+VAT+%7C+Company+Registration+%7C+Copyright+%7C+Trademark/@23.7927683,90.374504,17z/data=!4m8!3m7!1s0x3755b8bf5300eaad:0xe50b10a249f6394e!8m2!3d23.7927683!4d90.374504!9m1!1b1!16s%2Fg%2F11c54h2yxw?entry=ttu">
                            <span className='home-top bg-secondary opacity-75 p-2'>
                                Excellent 5 out of 5&nbsp;
                                <FaStar style={{ color: "yellow", marginBottom: "5px" }} /> Google
                            </span>
                        </Link>
                        <h1 className='text-center mb-4'>Customer Experiences With Us</h1>
                        <Testimonial isResponsive={responsiveClass} />
                    </div>
      </section>
    </>
  );
}
