import React from "react";
import vat_og_Img from "../assets/og-img/Vat Software.png";
import Meta from "./Meta";
import OfferedPackages from "./OfferedPackages";
export default function BusinessSetup() {
  return (
    <>
      <Meta
        title="VAT Calculator in Bangladesh | Online Vat Calculator"
        description="VAT Calculator in Bangladesh, Online Vat Calculator, vat software, vat return"
        image={vat_og_Img}
        keywords="Vat Calculator, online vat calculator, vat software, vat return,"
      />
      <div className="container-xxl  center-background1 m-widht">
        <h2 className="m-text-md">Business Setup Packages</h2>
      </div>

      <OfferedPackages />
    </>
  );
}
