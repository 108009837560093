import React, { useState } from "react";

function VATCalculator() {
  const [amount, setAmount] = useState("");
  const [vatRate, setVATRate] = useState(15); // Default VAT rate in Bangladesh is 15%
  const [vatAmount, setVATAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountExclude, setTotalAmountExclude] = useState(0);
  const [totalExcludingVat, setTotalExcludingVat] = useState(0);

  const calculateTotal = () => {
    const calculatedVAT = (parseFloat(amount) * vatRate) / 100;
    const total = parseFloat(amount) + calculatedVAT;

    setVATAmount(calculatedVAT.toFixed(2));
    setTotalAmount(total.toFixed(2));

    const exclusiveVatAmount =
      +amount - (+calculatedVAT / (+vatRate + 100)) * 100;

    setTotalAmountExclude(exclusiveVatAmount.toFixed(2));
    setTotalExcludingVat(amount - exclusiveVatAmount.toFixed(2));
  };

  const handleReset = () => {
    setAmount("");
    setVATRate(15); // Reset VAT rate to default
    setVATAmount(0);
    setTotalAmount(0);
    setTotalAmountExclude(0);
    setTotalExcludingVat(0);
  };
  return (
    <div className="calculator-container">
      <h1 style={{ color: "white" }}>VAT Calculator</h1>
      <div>
        <label
          style={{ color: "white", fontSize: "17px", fontWeight: "700" }}
          htmlFor="amount"
        >
          Total Amount:{" "}
        </label>
        <input
          type="number"
          value={amount}
          id="amount"
          placeholder="Enter Your Amount"
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div>
        <label
          style={{ color: "white", fontSize: "17px", fontWeight: "700" }}
          htmlFor="vatRate"
        >
          VAT Rate (%):{" "}
        </label>
        <input
          id="vatRate"
          type="number"
          value={vatRate}
          onChange={(e) => setVATRate(e.target.value)}
        />
      </div>
      <button
        className="form-submit-button"
        onClick={calculateTotal}
        style={{
          marginRight: "10px",
          fontSize: "17px",
          fontWeight: "700",
          border: "1px solid",
          borderRadius: "10px",
          padding: "8px 15px",
        }}
      >
        Calculate Total
      </button>
      <button
        className="form-submit-button"
        onClick={handleReset}
        style={{
          marginRight: "10px",
          fontSize: "17px",
          fontWeight: "700",
          border: "1px solid",
          borderRadius: "10px",
          padding: "8px 15px",
        }}
      >
        Reset
      </button>
      {vatAmount > 0 && totalAmount > 0 && totalAmountExclude > 0 && (
        <div className="result">
          <div style={{display:"flex",gap:"10px"}}>
            <div style={{display:"flex",padding:"0 10px", border:"1px solid", width:"100%",justifyContent:"space-between"}}>
              <p> <b>VAT Amount</b></p>
              <p> : {vatAmount}</p>
            </div> 
            <div style={{display:"flex",padding:"0 10px", border:"1px solid", width:"100%",justifyContent:"space-between"}}>
              <p> <b>Including VAT Amount</b></p>
              <p> : {totalAmount}</p>
            </div> 
           
          </div>
          <div style={{display:"flex",gap:"10px"}}>
          <div style={{display:"flex",padding:"0 10px", border:"1px solid", width:"100%",justifyContent:"space-between"}}>
            <p>  <b>Excluding VAT</b></p>
            <p> : {Number(totalExcludingVat).toFixed(2)}</p>
            </div> 
            <div style={{display:"flex",padding:"0 10px", border:"1px solid", width:"100%",justifyContent:"space-between"}}>
            <p>  <b>Excluding VAT Amount</b></p>
            <p> : {totalAmountExclude}</p>
            </div> 
          </div>
        </div>
      )}
    </div>
  );
}

export default VATCalculator;
