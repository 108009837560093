import React from "react";
import openForeignCompany from "../../assets/images/open-Foreign-Company.png";
import openForeignCompany_OG_img from "../../assets/og-img/Foreign Company Formation.png";
import Meta from "../Meta";

export default function RegisteredAddressChange() {
  return (
    <>
      <Meta
        title="Registered Address Change in Bangladesh | JK Associates"
        description="Bangladesh government allowed 100% equity ownership for foreign investor for business setup in bangladesh. Also, offered tax benefit and land, and policy and regulatory support. 
                A foreign equity company can buy land and properties in bangladesh with registered entity. A foreign investor is required to be registered with BIDA"
        image={openForeignCompany_OG_img}
        keywords="Foreign Company Registration, Setup Foreign Company Bangladesh, Company registration in Bangladesh, Foreign Company Formation in Bangladesh, Foreign company in Bangladesh, How to register a foreign company in Bangladesh, Company formation in Bangladesh, online company registration in bangladesh"
      />

      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">
              Registered Address Change
              </h1>
              <p>
                {" "}
                Foreign Company Formation Bangladesh: foreign investor 100%
                ownership is allowed An entrepreneur has to follow nine specific
                procedures and legal steps to set up a business in Bangladesh.
              </p>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img
                className="img-fluid animated pulse infinite"
                style={{ animationDuration: "3s" }}
                src={openForeignCompany}
                alt="Foreign Company Registration in Bangladesh"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
