import React from 'react'
import Marquee from "react-fast-marquee"
import foreign1 from "../../assets/images/Open-a-Foreign-Company-process.png"
import openForeignCompany from "../../assets/images/open-Foreign-Company.png"
import openForeignCompany_OG_img from "../../assets/og-img/Foreign Company Formation.png"
import CompanyFormationPackages from '../../components/CompanyFormationPackages'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import { fpRealaatedArticales } from '../../data/relatedArticale'

function  ForeignCompanyFormation() {
    const faqItems = [
        {
            title: 'Can a foreign company own land in Bangladesh?',
            content: <strong>As per the Foreign Private Investment (Promotion and Protection) Act of
                1980, foreigners can possess properties in Bangladesh. This is however contingent upon
                obtaining government authorisation, frequently requiring a protracted and unclear clearance
                procedure.</strong>
        },
        {
            title: 'How can I open a foreign company branch in Bangladesh?',
            content: <strong> Required Documents to Set up the Branch Office
                1. Foreign Company's Certificate of Incorporation
                2. Memorandum and Article of Association(AOA)
                3. Audited Financial Statements of Parent Company (Previous Two Years)
                4. Board Resolution for the Branch Office set-up in Bangladesh.
                5. Complete Information of Directors.
                6. Encasement certificate of 50,00 USD
                7. BIDA approval leter for branch office
                8. Get branch office incorporation certificate from RJSC</strong>
        },
        {
            title: 'What is a local representative of a foreign company?',
            content: <strong>The local representative is a statutory role whose responsibilities extend beyond being
                a local contact. Pursuant to Section 981 of the Act, a local representative is responsible and
                primarily answerable for complying with the Act on behalf of the foreign company.</strong>
        }
    ];
    return (
        <>
            <Meta title="Foreign Company Registration in Bangladesh | JK Associates"
                description="Bangladesh government allowed 100% equity ownership for foreign investor for business setup in bangladesh. Also, offered tax benefit and land, and policy and regulatory support. 
                A foreign equity company can buy land and properties in bangladesh with registered entity. A foreign investor is required to be registered with BIDA" 
                
                image={openForeignCompany_OG_img}
                keywords="Foreign Company Registration, Setup Foreign Company Bangladesh, Company registration in Bangladesh, Foreign Company Formation in Bangladesh, Foreign company in Bangladesh, How to register a foreign company in Bangladesh, Company formation in Bangladesh, online company registration in bangladesh"
            />

                   

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Foreign Company Registration</h1>
                            <p> Foreign Company Formation Bangladesh: foreign investor 100% ownership is allowed An 
                            entrepreneur has to follow nine specific procedures and legal steps to set up a business in Bangladesh.</p>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={openForeignCompany} alt='Foreign Company Registration in Bangladesh' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Foreign Company Formation Bangladesh: foreign investor 100% ownership is allowed An 
                        entrepreneur has to follow nine specific procedures and legal steps to set up a business in Bangladesh. At the very first, the entrepreneur
                        has to apply to the Registrar of Joint Stock Companies and Firms (RJSC) for Name
                        Clearance Certificate. After receiving the certificate, the next step is to pay stamp
                        duty at a Designated Bank and again apply to RJSC for registration. Then the company
                        makes a seal and open a bank account and then apply for trade license to respective
                        City Corporation or Municipal Corporations. After completing all those procedures,
                        the entrepreneur has to approach to the National Board of Revenue (NBR) for receiving
                        a TIN Certificate and registering with the Customs, Excise, and VAT Commissionerate.
                        Finally, the investor requires to apply to the Bangladesh Investment Development
                        Authority (BIDA) for registration.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Name Clearance Approval for Foreign Company Formation</b>
                    </p>
                    <p>
                        The first step is to apply to the Registrar of Joint Stock Companies and Firms (RJSC)
                        for receiving a name clearance approval for Foreign Company or any Company. This
                        is a pre-requisite for registration of a new company (other than Foreign Company and
                        Partnership firms) or a society or a trade organization. An applicant needs to apply
                        via the RJSC website. The RJSC office will check the database and issue name
                        clearance if it does not closely match or resembles any of the existing names. It
                        will be valid for the next 30 days. An applicant will have to apply for registration
                        within this timeline.<br /><br />

                        Legal Basis of the Certificate, the Companies Act- 1994 Responsible Agency, Registrar
                        of Joint Stock Companies and Firms (RJSC)
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  " >
                            <img className="img-fluid" src={foreign1} alt='Foreign Company Registration process in Bangladesh' />
                        </div>
                        <div className="col-lg-6  " >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Process Step for Foreign Company</p>
                                <p>

                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 1: Applicant fills in the online application
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 2: Opening an E-account an RJSC website
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 3: Preliminary name search on the RJSC website
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 4: Payment of fees to the designated Bank
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 5: Submits the money receipt
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 6: Obtain the name clearance certificate
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Step 7: Submits the money receipt
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Required Documents
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            A print out of the name clearance certificate
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Original copy of Money Receipt
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    PROCEDURE 02: Pay Stamp Duty at a Designated Bank
                                </p>
                                <p>
                                    Payment of stamp duty is made to the account of the Treasury at the EBL and One
                                    Bank. According to the Stamp Act, 1899 as amended on June 30th, 2020,
                                    for authorized share capital of up to BDT 1,000,000, the total stamp
                                    duty fees are BDT 12,300. These include registration filing fee BDT 1,200 for affixing stamps on the Articles of Association and BDT 1,520 for stamps on the Memorandum
                                    of Association. In addition, a certified copy of each document costs BDT
                                    250, The Registration fee's 15% VAT applicable.<br /><br />
                                    
                                    Legal Basis of the Registration, the Companies Act- 1994 Responsible
                                    Agency, Registrar of Joint Stock Companies and Firms (RJSC)
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    PROCEDURE 03: Process Step for Foreign Company Bangladesh
                                </p>
                                <p>
                                    Step 1: Preparing Memorandum of Association (MOA) and Articles of
                                    Association (AOA) as appropriate to the entity type Step 2: Visiting
                                    RJSC website to apply for company registration Step 3: Filling in Name
                                    Clearance Submission number and letter number Step 4: Filling in the
                                    prescribed forms Step 5: Attaching the MOA and AOA Step 6: Submitting
                                    the Registration Application Step 7: Depositing the required fee &
                                    receiving the payment slip form designated bank Step 8: Uploading
                                    document in online Step 9: Printing the uploaded documents and
                                    Submitting those documents & payment slip to RJSC office Step 10:
                                    Receiving a Certificate of Incorporation from the RJSC Office
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Required Documents for Foreign Company Formation</b>
                    </p>
                    <p>
                        The first step is to apply to the Registrar of Joint Stock Companies and Firms (RJSC)
                        for receiving a name clearance certificate for Foreign Company or any Company. This
                        is a pre-requisite for registration of a new company (other than Foreign Company and
                        Partnership firms) or a society or a trade organization. An applicant needs to apply
                        via the RJSC website. The RJSC office will check the database and issue name
                        clearance if it does not closely match or resembles any of the existing names. It
                        will be valid for the next 30 days. An applicant will have to apply for registration
                        within this timeline.<br /><br />

                        Legal Basis of the Certificate, the Companies Act- 1994 Responsible Agency, Registrar
                        of Joint Stock Companies and Firms (RJSC). online company registration in bangladesh.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Name clearance certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Memorandum and Articles of association
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Filled in Form I: Declaration on Registration of Company
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Filled in Form VI: Notice of Situation of Registered Office and of Any Change therein
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Filled in Form IX: Consent of Director to act
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Filled in Form X: List of Persons Consenting to be Directors
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Filled in Form XII: Particulars of the Directors, Manager and Managing Agents and of any change therein Evidence of Name Clearance
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Proof of payment (i.e. receipt from the designated bank) for Treasury Stamps
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                TIN Certificate of all Directors
                            </span>
                        </div>
                    </p>

                    <CompanyFormationPackages />

                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 04: Make a Company Seal</b>
                    </p>
                    <p>
                        Business founders can make a company seal at the Seal maker for BDT 100-200
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 05: Open a Bank Account</b>
                    </p>
                    <p>
                        If, the foreign equity company registered in Bangladesh. Open a bank account for the proposed company and transfer equity capital or 
                        transfer inward remittance from abroad and request for encasement certificate for foreign equity company registration to the bank.<br /><br />
                    
                        A company must first open a bank account In order to register for VAT. An entrepreneur
                        will go to the concerned Bank and open a Bank account.<br /><br />

                        In order to register for VAT, the company must first open a bank account. Detailed
                        procedures should be discussed with the Bank.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 06: Obtain a Trade License</b>
                    </p>
                    <p>
                        Getting a trade license is one of the important steps of starting a business in
                        Bangladesh. An entrepreneur needs to receive a trade license from City Corporation or
                        City Council of the respective business area. The trade license filled-in application
                        form must be accompanied by the required documents.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 07: Obtain a Tax Identification Number (TIN) Certificate for Company</b>
                    </p>
                    <p>
                        In order to start business operations, every company must receive TIN Certificate from
                        NBR. An entrepreneur can apply in online filling out prescribed online form and obtain
                        TIN Certificate instantly. Alternatively, the entrepreneur can register for taxes at
                        the appropriate taxation authority (Deputy Commission of Taxes of Company Circle,
                        Zonal Taxation Department) under the National Board of Revenue (NBR) and obtain a tax
                        identification number.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 08: VAT Registration</b>
                    </p>
                    <p>
                        For VAT purposes, companies register with the Customs, Excise, and VAT Commissionerate
                        under the National Board of Revenue. The company’s VAT is regulated by the Customs,
                        VAT and Excise Department of the region in which it operates. When an enterprise
                        submits a VAT Registration application to the VAT authority an application must
                        accomplish or enclosed the Trade License, Bank Solvency Certificate, TIN Certificate,
                        Copy of BOI Registration & Deed of Agreement.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE 09: Registration with the Bangladesh Investment Development Authority (BIDA)</b>
                    </p>
                    <p>
                        A foreign investor is required to be registered with BIDA. It is essential to obtain
                        any licenses and permits such as Import Registration Certificate, Export Registration
                        Certificate, Bond License, etc. to run business in Bangladesh. The BIDA registration
                        is also mandatory for obtaining an industrial plots in the special economic zone. The
                        investor needs to collect the application form from BIDA and submit the filled up
                        form along with supporting documents to the respective department of BIDA.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Process Step</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Step 1:  Collect a local investment registration form from Registration & Investment Division of BIDA
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Step 2: Deposit the Registration fee, as per fee schedule from any scheduled bank in favor of Bangladesh Investment Development Authority and collect a pay order/bank order
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Step 3: Submit the completed application form with supporting documents to the concerned section
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Step 4: BIDA reviews the application and documents
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Step 5: If the application is approved, the Registration Certificate will be issued
                            </span>
                        </div>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b> Required Documents</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Application in duly filled in the prescribed form
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Certificate of Incorporation along with Memorandum of Association (MOA)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Partnership Deeds
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Deeds of the proposed land
                                Project profile, if the total project cost exceeds BDT 100 million
                                Background of the proprietors in officials letterhead pad
                                Pay Order/Bank Order for applicable registration fee in favor of Bangladesh Investment Development Authority
                                TIN Certificate
                                Investing in Bangladesh: Incentives
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Deeds of the proposed land
                                Project profile, if the total project cost exceeds BDT 100 million
                                Background of the proprietors in officials letterhead pad
                                Pay Order/Bank Order for applicable registration fee in favor of Bangladesh Investment Development Authority
                                TIN Certificate
                                Investing in Bangladesh: Incentives
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Deeds of the proposed land
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Project profile, if the total project cost exceeds BDT 100 million
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Background of the proprietors in officials letterhead pad
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Pay Order/Bank Order for applicable registration fee in favor of Bangladesh Investment Development Authority
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                TIN Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Investing in Bangladesh: Incentives
                            </span>
                        </div>
                        The BIDA offers a range of valuable business-related services to the international
                        and national investment community. The office helps prospective investors and
                        promoted firms in obtaining official permits and documents required for conducting
                        business, including visas, work permits, and permanent residency permits. The BIDA
                        also assists investors in gaining access to utilities such as water, electricity,
                        gas, land, and telecommunications.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Information on Investment Opportunities</b>
                    </p>
                    <p>
                        Bangladesh has taken initiatives to establish 100 Special Economic Zones (SEZs) by 2030, (70 Government Approved Economic Zones and 30 Private Economic Zones). With investors already having started production in some of the zones, FDI has witnessed a significant boost. Bangladesh has already awarded pre-qualification licenses to 20 private economic zones and of them, 11 have been given final license.
                    </p>
                    <p>
                        Bangladesh Hi-Tech Park Authority has been established under the 'Bangladesh Hi-Tech Park Authority Act-2010' with the objective of creating an investment-friendly environment and creating employment through the development and growth of high-tech industries in the country. Since its inception, the Bangladesh Hi-Tech Park Authority has been working for socio-economic development by establishing Hi-Tech Parks / Software Technology Parks / IT Training and Incubation Centers.
                    </p>
                    <p>
                        Bangabandhu Sheikh Mujib Shilpa Nagar, one of the largest industrial areas in the country is being developed by BEZA (Bangladesh Economic Zones Authorities) on a contiguous land of 30,000 acres of Mrisarai, Sitakundu and Sonagazi- the adjoining upazilas of Chittagong and Feni with the target of making it a leading regional business hub. 

                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>One-Stop Service Center</b>
                    </p>
                    <p>
                        The BIDA coordinated the establishment of a One-Stop Service Center for
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License from respective city corporations and local government bodies are given within 48 hours, assuming all required documents are provided. Bangladesh Investment Development Authority with local government division insures this service.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company registration from Joint Stock Register’s office company registration complete within 48 hours.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Registration of Bangladesh Investment Development Authority is complete within a day, assuming all required documents are provided.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Electricity connection’s results are given within 10 days after receiving the application from BIDA where a representative of power development board authority is working.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Environmental certificates are given within 10 days from BIDA where a respective officer from the Environment Department is working.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                From Bangladesh Investment Development Authority representative of Titas Gas Authority, the result of gas connection is given within 10 days from the date of application received.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Foreign loan borrowing application disposed within 30 days assuming all required documents are provided.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                The tax-related complication is taken from BIDA by respective National Board of Revenue officers after getting an application from the entrepreneur and results are given within 10 days.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Online services also provided by BIDA.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Assistance for Land acquire is supported by BIDA.
                            </span>
                        </div>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                   {
                    fpRealaatedArticales
                   }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Foreign Company Formation FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default ForeignCompanyFormation