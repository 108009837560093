import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/authentication/Login.js";
import Register from "./components/authentication/Rgister.js";
import BankAccount from "./components/BankAccount.js";
import BranchOfficeInBd from "./components/BranchOfficeInBd.js";
import BusinessSetup from "./components/BusinessSetup.js";
import BusinessSetupInBD from "./components/BussinessSetupInBD.js";
import BuyingHouseLicense from "./components/BuyingHouseLicense.js";
import CartPage from "./components/cartAndCheckout/Cart.js";
import CheckoutPage from "./components/cartAndCheckout/Checkout.js";
import CityLiving from "./components/CityLiving.js";
import CleanTech from "./components/CleanTech.js";
import CompanyRegistrationInBD from "./components/CompanyRegistrationInBD.js";
import AGMHighCourtPermission from "./components/companySecretary/AGMHighCourtPermission.js";
import CourtSuccessionCertificate from "./components/companySecretary/CourtSuccessionCertificate.js";
import RegisteredAddressChange from "./components/companySecretary/RegisteredAddressChange.js";
import ShareAllotment from "./components/companySecretary/ShareAllotment.js";
import ShareTransfer from "./components/companySecretary/ShareTransfer.js";
import ShareTransmission from "./components/companySecretary/ShareTransmission.js";
import CompanySetup from "./components/CompanySetup.js";
import D33Agenda from "./components/D33Agenda.js";
import DesignMedia from "./components/DesignMidea.js";
import EducationSystem from "./components/EducationSystem.js";
import Employees from "./components/Employees.js";
import EpbLicense from "./components/EpbLicense.js";
import FamilyOffice from "./components/FamilyOffice.js";
import FinanceAndWealth from "./components/FinanceAndWealth.js";
import FoodAndAgriculture from "./components/FoodAndAgriculture.js";
import ForeignInvest from "./components/ForeignInvest.js";
import FreeZoneCompanies from "./components/FreeZoneCompanies.js";
import GetAJob from "./components/GetAJob.js";
import Headquarters from "./components/Headquaters.js";
import Health from "./components/Health.js";
import LegalSystem from "./components/LegalSystem.js";
import LicenseAndApproval from "./components/LicenseAndApproval.js";
import MainLandCompanies from "./components/MainLandCompanies.js";
import Manufactures from "./components/Manufactures.js";
import Multinational from "./components/Multinational.js";
import PageTracker from "./components/PageTracker";
import Partnership from "./components/Partnership.js";
import Proprietorship from "./components/Proprietorship.js";
import PublicTransport from "./components/PublicTransport.js";
import RealState from "./components/RealState.js";
import Regulations from "./components/Regulatinos.js";
import RetailAndEcommarce from "./components/RetailAndEcommarce.js";
import RoadsAndDriving from "./components/RoadsAndDriving.js";
import ScrollToTop from "./components/ScrollToTop";
import Smes from "./components/Smes.js";
import SupportForManufacture from "./components/SupportForManufacture.js";
import Technology from "./components/Technology.js";
import TextileDot from "./components/TextileDot.js";
import TradeAndLogistics from "./components/TradeAndLogistics.js";
import UnifiedLicense from "./components/UnifiedLicence.js";
import VcPcCompanies from "./components/VcPcCompanies.js";
import VisaEntry from "./components/VisaEntry.js";
import WorkRemotely from "./components/WorkRemotly.js";
import Resource from "./root/Resource.jsx";
import Root from "./root/Root";
import About from "./screen/About.js";
import CompanyFormationViewFormPage from "./screen/CompanyFormationViewFormPage";
import ContactUs from "./screen/ContactUs";
import BlogCard from "./screen/Footer/BlogCard";
import CoSpace from "./screen/Footer/CoSpace";
import PropertyConsultancyPage from "./screen/Footer/PropertyConsultancyPage";
import VATCalculatorPage from "./screen/Footer/VATCalculatorPage";
import VisaConsultancyPage from "./screen/Footer/VisaConsultancyPage";
import BidaPermission from "./screen/Foreign Entity/BidaPermission";
import ForeignCompanyDirectorService from "./screen/Foreign Entity/ForeignCompanyDirectorService";
import ForeignCompanyFormation from "./screen/Foreign Entity/ForeignCompanyFormation";
import ForeignCompanyRegisteredAddress from "./screen/Foreign Entity/ForeignCompanyRegisteredAddress";
import ForeignDelegates from "./screen/Foreign Entity/ForeignDelegates";
import ForeignDirectInvestment from "./screen/Foreign Entity/ForeignDirectInvestment";
import PrivateInvestmentVISA from "./screen/Foreign Entity/PrivateInvestmentVISA";
import WorkPermitVisaInBangladesh from "./screen/Foreign Entity/WorkPermitVisaInBangladesh";
import GetStartedPage from "./screen/GetStartedPage";
import HomePage from "./screen/home/homePage";
import BtclDomain from "./screen/Intellectual Property Screen/BtclDomain";
import Copyright from "./screen/Intellectual Property Screen/Copyright";
import IndustrialDesign from "./screen/Intellectual Property Screen/IndustrialDesign";
import IprEnforcement from "./screen/Intellectual Property Screen/IprEnforcement";
import Patent from "./screen/Intellectual Property Screen/Patent";
import Trademark from "./screen/Intellectual Property Screen/Trademark";
import WebDevelopment from "./screen/Intellectual Property Screen/WebDevelopment";
import CivilLitigationScreen from "./screen/Litigation/CivilLitigationScreen";
import CriminalLitigationScreen from "./screen/Litigation/CriminalLitigationScreen";
import PaymentProcess from "./screen/PaymentProcess";
import PrivacyPolicy from "./screen/PrivacyPolicy";
import RefundAndReturnPlolicy from "./screen/RefundAndReturnPlolicy";
import CompanyDissolution from "./screen/Regulatory License/Company-dissolution.js";
import CustomsBondLicense from "./screen/Regulatory License/CustomsBondLicense";
import EnvironmentClearanceCertificate from "./screen/Regulatory License/EnvironmentClearanceCertificate";
import FactoryLicense from "./screen/Regulatory License/FactoryLicense";
import FireLicenseScreen from "./screen/Regulatory License/FireLicenseScreen";
import SiteMap from "./screen/SiteMap";
import BstiPermission from "./screen/StartupScreen/BstiPermission";
import CompanyFormation from "./screen/StartupScreen/CompanyFormation";
import ExportLicense from "./screen/StartupScreen/ExportLicense";
import ImportLicense from "./screen/StartupScreen/ImportLicense";
import LoanConsultancy from "./screen/StartupScreen/LoanConsultancy";
import OnePersonCompany from "./screen/StartupScreen/OnePersonCompany";
import PartnershipRegistration from "./screen/StartupScreen/PartnershipRegistration";
import ProjectProfile from "./screen/StartupScreen/ProjectProfile";
import RJSCReturnFilling from "./screen/StartupScreen/RJSCReturnFilling";
import SocietyRegistration from "./screen/StartupScreen/SocietyRegistration";
import TradeLicense from "./screen/StartupScreen/TradeLicense";
import UddoktaPackage from "./screen/StartupScreen/UddoktaPackage";
import AccountService from "./screen/Taxation/Audit/AccountService";
import BookeepingServices from "./screen/Taxation/Audit/BookeepingServices";
import AccountingSoftware from "./screen/Taxation/Audit/Business Software/AccountingSoftware";
import CompanyPortfolio from "./screen/Taxation/Audit/Business Software/CompanyPortfolio";
import ERPSoftware from "./screen/Taxation/Audit/Business Software/ERPSoftware";
import HRManagementSoftware from "./screen/Taxation/Audit/Business Software/HRManagementSoftware";
import VatSoftware from "./screen/Taxation/Audit/Business Software/VatSoftware";
import SmallBusiness from "./screen/Taxation/Audit/SmallBusiness";
import BINRegistration from "./screen/Taxation/BINRegistration";
import CorporateTax from "./screen/Taxation/CorporateTax";
import ETINCertificate from "./screen/Taxation/ETINCertificate";
import IndividualTax from "./screen/Taxation/IndividualTax";
import ValueAddedTax from "./screen/Taxation/ValueAddedTax";
import TermAndCondition from "./screen/TermAndCondition";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <PageTracker />
      <Routes>
        <Route path="/" element={<Root />}>
          {/* <Route path="/" element={<Base />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route
            rel="canonical"
            path="/company-formation-in-bangladesh"
            element={<CompanyFormation />}
          />
          <Route
            path="/one-person-company-registration-bangladesh"
            element={<OnePersonCompany />}
          />
          <Route
            path="/society-registration-in-bangladesh"
            element={<SocietyRegistration />}
          />
          <Route
            path="/partnership-registration-in-bangladesh"
            element={<PartnershipRegistration />}
          />
          <Route path="/rjsc-return-filing" element={<RJSCReturnFilling />} />
          <Route
            path="/loan-consultancy-services"
            element={<LoanConsultancy />}
          />
          <Route path="/import-license-irc" element={<ImportLicense />} />
          <Route path="/export-license-erc" element={<ExportLicense />} />
          <Route path="/bsti-permissions" element={<BstiPermission />} />
          <Route path="/trade-license" element={<TradeLicense />} />
          <Route path="/project-profile" element={<ProjectProfile />} />
          <Route path="/startup-packages" element={<UddoktaPackage />} />
          <Route
            path="/trademark-registration-in-bangladesh"
            element={<Trademark />}
          />
          <Route
            rel="canonical"
            path="/copyright-registration"
            element={<Copyright />}
          />
          <Route rel="canonical" path="/patent" element={<Patent />} />
          <Route path="/industrial-design" element={<IndustrialDesign />} />
          <Route
            rel="canonical"
            path="/ipr-enforcement"
            element={<IprEnforcement />}
          />
          <Route rel="canonical" path="/btcl-dr" element={<BtclDomain />} />
          <Route
            rel="canonical"
            path="/web-development"
            element={<WebDevelopment />}
          />
          <Route path="/income-tax" element={<IndividualTax />} />
          <Route path="/corporate-tax" element={<CorporateTax />} />
          <Route path="/vat-online-service" element={<ValueAddedTax />} />
          <Route
            path="/tax-identification-number-e-tin"
            element={<ETINCertificate />}
          />
          <Route
            path="/vat-registration-certificate-in-bangladesh"
            element={<BINRegistration />}
          />
          <Route path="/account-service" element={<AccountService />} />
          <Route
            path="/bookkeeping-services"
            element={<BookeepingServices />}
          />
          <Route
            path="/small-business-in-bangladesh"
            element={<SmallBusiness />}
          />
          <Route path="/acounting-software" element={<AccountingSoftware />} />
          <Route path="/erp-software" element={<ERPSoftware />} />
          <Route
            rel="canonical"
            path="/vat-software"
            element={<VatSoftware />}
          />
          <Route
            path="/hr-management-software"
            element={<HRManagementSoftware />}
          />
          <Route
            rel="canonical"
            path="/company-portfolio"
            element={<CompanyPortfolio />}
          />
          <Route path="/civil-litigation" element={<CivilLitigationScreen />} />
          <Route
            rel="canonical"
            path="/criminal-litigation"
            element={<CriminalLitigationScreen />}
          />
          <Route
            path="/foreign-company-registration-in-bangladesh"
            element={<ForeignCompanyFormation />}
          />
          <Route
            path="/foreign-direct-investment"
            element={<ForeignDirectInvestment />}
          />
          <Route path="/bida-permission" element={<BidaPermission />} />
          <Route
            path="/work-permit-visa"
            element={<WorkPermitVisaInBangladesh />}
          />
          <Route
            path="/private-investment-visa"
            element={<PrivateInvestmentVISA />}
          />
          <Route path="/foreign-delegates" element={<ForeignDelegates />} />
          <Route
            path="/foreign-company-director-service"
            element={<ForeignCompanyDirectorService />}
          />
          <Route
            path="/foreign-company-registered-address"
            element={<ForeignCompanyRegisteredAddress />}
          />
          <Route
            rel="canonical"
            path="/fire-license"
            element={<FireLicenseScreen />}
          />
          <Route
            rel="canonical"
            path="/factory-license"
            element={<FactoryLicense />}
          />
          <Route
            path="/customs-bond-license"
            element={<CustomsBondLicense />}
          />
          <Route
            rel="canonical"
            path="/environment-clearance-certificate"
            element={<EnvironmentClearanceCertificate />}
          />
          <Route
            rel="canonical"
            path="/company-winding-up"
            element={<CompanyDissolution />}
          />
          <Route
            rel="canonical"
            path="/buying-house-licensing-in-bangladesh"
            element={<BuyingHouseLicense />}
          />
          <Route
            rel="canonical"
            path="/textile-dot-license"
            element={<TextileDot />}
          />
          <Route rel="canonical" path="/epb-license" element={<EpbLicense />} />
          {/* <Route path="/about-us/" element={<AboutUs />} /> */}
          <Route path="/about-us/" element={<About />} />
          <Route
            rel="canonical"
            path="/payment-process"
            element={<PaymentProcess />}
          />
          <Route
            rel="canonical"
            path="/refund-policy"
            element={<RefundAndReturnPlolicy />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-condition" element={<TermAndCondition />} />
          <Route rel="canonical" path="/contact-us" element={<ContactUs />} />
          <Route rel="canonical" path="/sitemap" element={<SiteMap />} />
          <Route path="/vat-calculator" element={<VATCalculatorPage />} />
          <Route path="/business-setup-package" element={<BusinessSetup/>} />
          <Route
            rel="canonical"
            path="/visa-consultancy"
            element={<VisaConsultancyPage />}
          />
          <Route
            path="/property-consultancy"
            element={<PropertyConsultancyPage />}
          />
          <Route rel="canonical" path="/co-space" element={<CoSpace />} />
          <Route
            rel="canonical"
            path="/company-formation-form-page"
            element={<CompanyFormationViewFormPage />}
          />
           <Route path="/share-transfer" element={<ShareTransfer/>} />
        <Route path="/share-allotment" element={<ShareAllotment />} />
        <Route path="/share-transmission" element={<ShareTransmission />} />
        <Route path="/registered-address-change" element={<RegisteredAddressChange />} />
        <Route path="/AGM-high-court-permission" element={<AGMHighCourtPermission/>} />
        <Route path="/court-succession-certificate" element={<CourtSuccessionCertificate/>} />
        </Route>
        <Route path="/get-started-page" element={<GetStartedPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/resource" element={<Resource />}>
          <Route
            index
            element={
              <Navigate to="/resource/why-investment-in-bangladesh" replace />
            }
          />
          <Route
            path="/resource/why-investment-in-bangladesh"
            element={<BusinessSetupInBD />}
          />
          <Route rel="canonical" path="/resource/blog" element={<BlogCard />} />
          <Route path="/resource/proprietorship" element={<Proprietorship />} />
          <Route path="/resource/partnership" element={<Partnership />} />
          <Route
            path="/resource/company-registration-bd"
            element={<CompanyRegistrationInBD />}
          />
          <Route path="/resource/family-office" element={<FamilyOffice />} />
          <Route path="/resource/headquarters" element={<Headquarters />} />
          <Route path="/resource/manufactures" element={<Manufactures />} />
          <Route
            path="/resource/multinational-corporations"
            element={<Multinational />}
          />
          <Route path="/resource/vc-pc-companies" element={<VcPcCompanies />} />
          <Route
            path="/resource/vision-2040-bangladesh"
            element={<D33Agenda />}
          />
          <Route path="/resource/technology-and-ict" element={<Technology />} />
          <Route
            path="/resource/construction-and-real-estate"
            element={<RealState />}
          />
          <Route
            path="/resource/healthcare-and-pharmaceuticals"
            element={<Health />}
          />
          <Route
            path="/resource/food-and-agriculture"
            element={<FoodAndAgriculture />}
          />
          <Route
            path="/resource/energy-and-cleantech"
            element={<CleanTech />}
          />
          <Route
            path="/resource/finance-and-wealth"
            element={<FinanceAndWealth />}
          />
          <Route
            path="/resource/retail-and-ecommerce"
            element={<RetailAndEcommarce />}
          />
          <Route
            path="/resource/trade-and-logistics"
            element={<TradeAndLogistics />}
          />
          <Route
            path="/resource/design-media-and-entertainment"
            element={<DesignMedia />}
          />
          <Route
            path="/resource/company-set-up-options"
            element={<CompanySetup />}
          />
          <Route
            path="/resource/mainland-companies"
            element={<MainLandCompanies />}
          />
          <Route
            path="/resource/free-zone-companies"
            element={<FreeZoneCompanies />}
          />
          <Route
            path="/resource/employees-and-administration"
            element={<Employees />}
          />
          <Route
            path="/resource/regulations-laws-and-tax"
            element={<Regulations />}
          />
          <Route path="/resource/support-for-smes" element={<Smes />} />
          <Route
            path="/resource/support-for-manufacturers-and-exporters"
            element={<SupportForManufacture />}
          />
          <Route
            path="/resource/bangladesh-unified-licence"
            element={<UnifiedLicense />}
          />
          <Route
            path="/resource/branch-office-bd"
            element={<BranchOfficeInBd />}
          />
          <Route
            path="/resource/business-licenses-bangladesh"
            element={<LicenseAndApproval />}
          />
          <Route path="/resource/visas-and-entry" element={<VisaEntry />} />
          <Route path="/resource/get-a-job" element={<GetAJob />} />
          <Route
            path="/resource/open-a-bank-account"
            element={<BankAccount />}
          />
          <Route
            path="/resource/introduction-to-real-estate"
            element={<RealState />}
          />
          <Route
            path="/resource/roads-and-driving"
            element={<RoadsAndDriving />}
          />
          <Route
            path="/resource/public-transport"
            element={<PublicTransport />}
          />
          <Route
            path="/resource/education-ecosystem"
            element={<EducationSystem />}
          />
          <Route path="/resource/health-and-wellness" element={<Health />} />
          <Route
            path="/resource/resources-for-city-living"
            element={<CityLiving />}
          />
          <Route path="/resource/work-remotely" element={<WorkRemotely />} />
          <Route path="/resource/legal-system" element={<LegalSystem />} />
          <Route
            path="/resource/foreign-invest-bd"
            element={<ForeignInvest />}
          />
        </Route>
      
      </Routes>
    </BrowserRouter>
    // <RouterProvider router={router} />
  );
}

export default App;
