import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import TestimonialCard from './TestimonialCard';


const testimonials = [
  { name: "Mehedi Hasan", review: "Really good service and great job they have done. I want to work with them again and again. Highly recommended.", time: "1 year ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjVOTwMNSSRd3ptU_hasPLZNEhpzB-2Y1Z5MSnDicdmIhjajw7nc=s120-c-rp-mo-br100" },
  { name: "Niluman Meghla", review: "ধন্যবাদ JK Associates কে আমার ট্যাক্স ফাইলের সমস্যাটা সমাধান করে সঠিক সময়ে ট্যাক্স জমা করে দেবার জন্যে 🥰", time: "5 months ago", imgUrl: "https://lh3.googleusercontent.com/a/ACg8ocKjK2L1rnReelJdV9iPYfiktHFNaDwzg3l_OHiSc1Wpk162Gg=s120-c-rp-mo-br100" },
  { name: "Zawadkhan Washy", review: "One the best income tax consultancy firm in bangladesh. They take care my income tax file. Highly Recommend.", time: "3 years ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjVLgxb4nCtxbpnmYin6AvO7C4TnTJ3ukfh8tOtTUMW3i4SRYQg=s120-c-rp-mo-br100" },
  { name: "Sukha Rangan Das", review: "Our tradelicense for IT company was done perfectly by jk associates. Great service, behaviour is very good.", time: "2 year ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjUGyRaoAYdVzsghdNWDgyWjGx8_Uax5RjIw0EeKjkEpLeJyVyg=s120-c-rp-mo-br100" },
  { name: "Mohosin Saikat", review: "ts a nice organisation. I happy with work with them. I made my company's registration and others services.", time: "5 months ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjVmYZJfH51H3IGXNlQUKc2HFGlX4mIF37dFFDyH7bS-z7QItBuLUQ=s120-c-rp-mo-br100" },
  { name: "jamal chowdhury", review: "We are very happy with the corporate service by JK associates. Our Company Formation was done successfully.", time: "2 years ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjXq91BcG1spwiJ6J83XsO9LIDljc0vkIPqS--paVtPyQEMcyu8x=s120-c-rp-mo-br100" },
  { name: "Emon Press", review: "Very expert team and good services. Best company consultancy and tax lawyer firm in Bangladesh. highly Recommend.", time: "5 years ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjX-q1IPSWXa6KM_1yE5GVHlvaGRoo87jH1euW22iQjwDgd-QYoQ=s120-c-rp-mo-br100" },
  { name: "Fahim Touqir", review: "Good Job JK Associates. Thanks for your support to set up a company. Looking forward to working with you.", time: "9 months ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjVIvnFRlg8G8rtu9eOMJUG9I-IpiQLF9ecLe1fR2LmSQHTqXPw=s120-c-rp-mo-br100" },
  { name: "Most. Laila Akter", review: "Our BIDA Registration process was done by JK associates. It was an amazing experience working with them.", time: "2 years ago", imgUrl: "https://lh3.googleusercontent.com/a-/ALV-UjV-wDSECF-2JJRsDvuC6OKHEuPEBHDk1jcgj80KEf4ijGU4YKI=s120-c-rp-mo-br100" },
];

export default function Testimonial({isResponsive}) {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    isResponsive ?
    <Slider {...settings}>
      {
        testimonials?.map((item,index)=><TestimonialCard key={index} item={item}/>)
      }
    </Slider>
    :<Slider {...settings} >
      <div>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {
            testimonials?.slice(0,3)?.map((item,index)=><TestimonialCard key={index} item={item}/>)
          }
        </div>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {
            testimonials?.slice(3,6)?.map((item,index)=><TestimonialCard key={index} item={item}/>)
          }
        </div>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {
            testimonials?.slice(6,9)?.map((item,index)=><TestimonialCard key={index} item={item}/>)
          }
        </div>
      </div>
    </Slider>
  )
}
